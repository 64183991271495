
<template>
    <div >
        <v-sheet rounded="sm" class ="event-load-wrap">

            <table>
                <tr v-for="e in event_stack" :key="e.time">
                    <td>
                        <center>

                            <div class="pulsing-green-light"></div>
                        </center>
                    </td>
                    <td> {{ e.timestr }}</td>
                    <td> {{ e.msg }}</td>
                </tr>
            </table>

        </v-sheet>

    </div>
</template>
<script>

async function* get_streaming(url) {
    try {
        const response = await fetch(url); 
        const reader = response.body.getReader();
        while (true) {
                const { done, value } = await reader.read();
                if (done) { break; }
                const json = JSON.parse(new TextDecoder().decode(value));
                yield json;
        }
    } 
    catch (error) { console.error('Error:', error); }
}


// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'stream-loading-actions',
    components:{
    },
    emits:[
    ],
    data(){return {
        msg: '',
        event_stack:[],
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.loadAndDisplayOutput().then(r=>r)
    
    },
    methods:{
        ...mapActions([]),
        async loadAndDisplayOutput() {
            const url = `${this.db_url}load/sys/load_updates_stream`
            const loader = get_streaming(url);
            while (true) {
                const { value, done } = await loader.next();
                if (done) { break; }
                console.log(value);
                this.msg = value.msg
                this.stack_events(value)
                
            }
        },
        stack_events(event){
            event.timestr = new Date(event.time*1000).toTimeString().split(' ')[0]
            this.event_stack.unshift(event)
            if(this.event_stack.length > 5){this.event_stack = this.event_stack.slice(0,5)}
        }
    },
    computed:{
        ...mapState(['db_url']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.event-load-wrap{
    height:300px;
    overflow-y: scroll;
}
.pulsing-green-light{

    width: 10px;
  height: 10px;
  background-color: rgb(155, 255, 61);
  box-shadow: 0 0 20px rgba(155, 255, 61, 0.7); 
//   animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    background-color: gray;
    // transform: scale(1);
  }
  50% {
    background-color: rgb(155, 255, 61);
    //transform: scale(1.1);
    // box-shadow: 0 0 30px rgba(155, 255, 61, 0.7);
  }
  100% {
    background-color: gray;
    // transform: scale(1);
  }
}
</style>