<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field label="Name" v-model="query.name" outlined rounded dense ></v-text-field>
                    <v-text-field label="Wallet" v-model="query.wallet" outlined rounded dense ></v-text-field>
                    <v-textarea label="Reason for request... " outlined rounded dense></v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                    <v-btn class="ma-0"  elevation="2"   block @click="do_signup_request" > Request Access </v-btn>
                </v-col>

            </v-row>
        </v-container>
                <v-snackbar v-model="snackbar" > {{ msg }}
            <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false" >
                Close
            </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'request-user-acc-form-waitinglist',
    components:{
    },
    emits:[
    ],
    data(){return {
        query:{
            name:'',
            wallet:'',
            reason_for_request:'',
        },
        snackbar:false,
        msg: '',
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['signup_request']),
        do_signup_request(){
            this.signup_request({qs:{},data:{}}).then((r)=>{
                this.msg = r.data
                this.snackbar = true
                this.query ={ name:'', wallet:'', reason_for_request:'', }
            })
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>