<template>
    <div>
        <v-btn class="ma-1" elevation="2" color="default" block  @click="sign_tx" >
            <img height="20" :src="`${db_url}static/img/metamask_logo.png`" class="px-1" alt="">
            Metamask
       
        </v-btn>
        <v-snackbar v-model="snackbar" > {{ login_feedback }}
            <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false" >
                Close
            </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
//import { ethers } from 'ethers';
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'metamask-login',
    components:{
    },
    emits:[
    ],
    data(){return {
        router_address: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
        login_feedback: 'no action', 
        snackbar:false,
        }
    },
    props: { 
        user_address:{type:String,default:'0x24ce26bb1c53191b1ade241ce7ca7ebe245d3ff7'},
        currency_address:{type:String,default:''},
        amount_eth:{type:Number,default:0.3},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['login_metamask']),

        async connectToMetaMask() {
            if (window.ethereum == undefined){ console.error('Metamask Was Not Found'); return}
            this.provider = new ethers.providers.Web3Provider(window.ethereum);
            this.signer = this.provider.getSigner();
            console.log("Connected to metamask", this.signer);
            await this.provider.send("eth_requestAccounts", []);
            const account = await this.signer.getAddress()
            if(account != ''){
                console.info("Metamask Connected", account)
                this.is_connected = true
                this.btn_text = this.disp_addr(account)
                this.set_active_wallet(account)
                this.account = {address:account, wallet:'metamask'}
                this.getEthBalance(account)

                window.ethereum.on('accountsChanged', (accounts) => {
                    const newAddress = accounts[0];
                    this.btn_text = this.disp_addr(newAddress)
                    this.set_active_wallet(newAddress)
                    console.log('MetaMask address changed:', newAddress);
                    this.getEthBalance(newAddress)
                });
            }
        },
        async sign_tx(){
            if (typeof window.ethereum !== 'undefined') {
                        const provider = window.ethereum;
                        provider.request({ method: 'eth_requestAccounts' }).then(async (accounts) => {
                            const fromAddress = accounts[0];
                            const signMessage =  "RGM sign in token: KappaDeltaSigmaA483"
                            const msg = `0x${Buffer.from(signMessage, 'utf8').toString('hex')}`;
                            const sign = await ethereum.request({ method: 'personal_sign', params: [msg, fromAddress], });
             
                            const data = {message:signMessage, sign:sign, account:fromAddress, accounts:accounts}
                            this.login_metamask(data).then((r)=>{
                                this.login_feedback = r.data
                                this.snackbar = true
                                setTimeout(()=>{window.location= '/'}, 3000)
                                })
                    });
                    } 
                    else { console.error('MetaMask is not installed or enabled.'); }

        }

    },
    computed:{
        ...mapState(['active_wallet','investement_amt','db_url']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>