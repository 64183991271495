<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field label="User Name" v-model="login_data.username" outlined rounded dense hide-details  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field label="Password" type="password" v-model="login_data.password" outlined rounded dense hide-details  ></v-text-field>
                    
                </v-col>
                <v-col cols="12" md="12">
                    <v-btn class="ma-0"  elevation="2"  block @click="do_log_in_dj" >Login</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-snackbar v-model="snackbar" > 
                    {{ msg }}
                <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false" >
                    Close
                </v-btn>
                </template>
        </v-snackbar>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'form-user-pw-login',
    components:{
    },
    emits:[
    ],
    data(){return {
        login_data : {
            username:'',
            password:'',
        },
        msg:'',
        snackbar:false,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['login_usr_pw']),
        do_log_in_dj(){
            this.login_usr_pw({qs:{username: this.login_data.username, password:this.login_data.password},data:{}}).then((r)=>{
                this.msg = r.data
                this.snackbar = true
                if (this.msg == 'Logged In'){
                    setTimeout(()=>{
                        window.location = '/'
                        }, 1500
                    )
                } 

            })
            // window.location = `${this.db_url}admin/login/disabled n}`
        },

    },
    computed:{
        ...mapState(['db_url']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>