<template>
    <div class="login-wrap">
        <v-container fluid>
            <v-row>

                <v-col cols="12" md="6" offset-md="3">
                    <app-sheet title="access">
                        <v-tabs centered color="white" >
                                <v-tab>Login</v-tab>
                                <v-tab>Sign up</v-tab>
                                <v-tab link to="/about-explain" >about</v-tab>
                                <v-tab-item>
                                    <div class="login-wrap">
                                        <div class="pw-wrap">
                                            <center>Login with pw</center>        
                                            <form-user-pw-login></form-user-pw-login>
                                        </div>
                                        <div class="mm-wrap">
                                                <center> or login with </center>
                                                <metamask-login></metamask-login>
                                        </div>
                                    </div>
                                </v-tab-item>
                                <v-tab-item>
                                    <request-user-acc-form-waitinglist></request-user-acc-form-waitinglist>
                                </v-tab-item>
                        </v-tabs>

                    </app-sheet>
                    
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import AppSheet from '@/components/app-sheet.vue'
import FormUserPwLogin from '@/components/form-user-pw-login.vue'
import MetamaskLogin from '@/components/metamask-login.vue'
import RequestUserAccFormWaitinglist from '@/components/request-user-acc-form-waitinglist.vue'
import StreamLoadingActions from '@/components/stream-loading-actions.vue'
export default {
    name: 'view-login',
    components:{
        MetamaskLogin,
        StreamLoadingActions,
        FormUserPwLogin,
        AppSheet,
        RequestUserAccFormWaitinglist 
    },
    emits:[
    ],
   data(){return {
        model_open:true,

        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
        do_log_in(){
            window.location = `${this.db_url}admin/login/?next=${window.location.origin}`
        },
        speak(text){
            text = this.what_can_you_do.sections[0].body
            console.log('speaking', text);
            const speech = new SpeechSynthesisUtterance(text);
            window.speechSynthesis.speak(speech);
        }
    },
    computed:{
        ...mapState(['db_url','is_mobile']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">

.login-wrap{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    justify-content: space-around;
    padding: 1em;
    .pw-wrap{
    display: flex;
    width: 50%;
    flex-direction: column;

    }
    .mm-wrap{
display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 50%;

    }
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
}

.cell-width-1 {
  width: 15%;
}

.cell-width-2 {
  width: 15%;
}

.cell-width-3 {
  width: 70%;
}




p{
    max-width: 30vw;
    min-width: 200px;
    text-align: center;
    color:rgba(255, 255, 255, 0.842);
}
.description{
    font-size:0.8em
}

.layers-table{
    tr,td{
        background:rgba(0, 0, 0, 0.03)
    }
    th{
        white-space: nowrap;
        padding:1em;
    }
    td{
        text-align: center;
        padding:1em;
        font-size: 13px;
    }
    tr:hover{
        background: none;
    }
    li{
        text-align: left;
    }
}

.explain-card{
    height:100%;
}
</style>